export class PrivacyPolicy {
  constructor() {
    const agree = document.querySelector('.js-privacy-policy');
    const element = document.querySelector('.js-privacy-check');
    const checkbox = document.getElementById("agree-1");
    const submitBtn = document.querySelector(".js-submit"); // 先にsubmitBtnを宣言
    if (location.pathname === '/contact/confirm/') {
      element.classList.remove('is-disabled');
    }
    // js-privacy-policyが存在しない場合は終了
    if (!agree || !element || !checkbox || !submitBtn) return;

    // チェックボックスが最初からチェックされている場合
    if (checkbox.checked) {
      element.classList.remove('is-disabled');
      submitBtn.disabled = false; // チェックされている場合、送信ボタンを有効化
    } else {
      submitBtn.disabled = true; // チェックされていない場合、送信ボタンを無効化
    }

    // プライバシーポリシーのスクロールイベント
    agree.addEventListener('scroll', () => {
      if (Math.abs(agree.scrollHeight - agree.clientHeight - agree.scrollTop) <= 1) {
        checkbox.disabled = false;
        element.classList.remove('is-disabled');
      }
    });

    // チェックボックスのクリックイベント
    checkbox.addEventListener("click", () => {
      submitBtn.disabled = !checkbox.checked; // チェックの状態に応じて送信ボタンを有効/無効化
    });


  }
}
