import { gsap } from 'gsap'
export class Accordion {
  constructor() {
    const acoTrgs = document.querySelectorAll('.js-aco_trg')
    const acoTrgArr = Array.prototype.slice.call(acoTrgs, 0)
    acoTrgArr.forEach(function (target) {
      target.setAttribute('aria-expanded', false)
      target.nextElementSibling.setAttribute('aria-hidden', true)
      //クリックした場合
      target.addEventListener('click', (event) => {
        let $this = event.currentTarget
        acoFunc($this)
      })
      //キーボード操作
      target.addEventListener('keypress', (event) => {
        if (event.keyCode === 13) {
          let $this = event.currentTarget
          acoFunc($this)
        }
      })
    })

    //768px以下
    if (window.innerWidth < 768) {
      const acoTabTrgs = document.querySelectorAll('.js-aco_trg_sp')
      const acoTabTrgArr = Array.prototype.slice.call(acoTabTrgs, 0)
      acoTabTrgArr.forEach(function (target) {
        target.addEventListener('click', (event) => {
          let $this = event.currentTarget
          acoFunc($this)
        })
      })
    }

    const acoFunc = (elm) => {
      let content = elm.nextElementSibling

      let contentHeight = content.scrollHeight
      elm.classList.toggle('is-act')

      if (elm.classList.contains('is-act')) {
        gsap.to(content, {
          duration: 0.7,
          ease: 'power1.out',
          height: contentHeight + 'px',
          onStart: () => {
            content.classList.add('is-show')
          },
          onComplete: () => {
            content.style.height = 'auto'
            elm.setAttribute('aria-expanded', true)
            content.setAttribute('aria-hidden', false)
          },
        })
      } else {
        gsap.to(content, {
          height: 0,
          ease: 'power1.out',
          duration: 0.7,
          onComplete: () => {
            content.classList.remove('is-show')
            elm.setAttribute('aria-expanded', false)
            content.setAttribute('aria-hidden', true)
          },
        })
      }
    }
  }
}
