import Vue from 'vue/dist/vue.esm.js'

export class SimulationAttachedTime {
  constructor() {
    if (document.getElementById('js-simulation_attached_time') === null) return
    const wrap = document.getElementById('js-simulation_attached_time')
    const radioList = Array.prototype.slice.call(wrap.querySelectorAll(`input[type='checkbox']`), 0)
    let priceList = []
    radioList.forEach(function (el) {
      priceList.push(el.getAttribute('data-price'))
    })
    const app3 = new Vue({
      el: '#js-simulation_attached_time',
      data: {
        isAttached: [],
        isTime: '',
      },
      computed: {
        attachedTotal: function () {
          let result = 0
          this.isAttached.forEach((val) => {
            result += Number(priceList[val])
          })
          if (this.isTime) {
            result = result * this.isTime
          }
          result = result.toLocaleString()
          return result
        },
      },
    })
  }
}
