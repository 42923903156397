import Swiper, { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'

export class FacilitySlider {
  constructor() {
    if (document.getElementById('js-facility_kv_slider') === null) return

    let facilitySwiper = new Swiper('#js-facility_kv_slider', {
      modules: [Navigation],
      loop: true,
      centeredSlides: true,
      slidesPerView: 1.2,
      spaceBetween: 15,
      breakpoints: {
        767: {
          slidesPerView: 1.63,
          spaceBetween: 40,
        },
      },
      navigation: {
        nextEl: '.js-arrow_next',
        prevEl: '.js-arrow_prev',
      },
    })
  }
}
