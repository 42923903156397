import { gsap } from 'gsap'

export class Modal {
  constructor() {
    document.addEventListener('DOMContentLoaded', function () {
      const showTrg = document.querySelectorAll('.js-modal_trg')
      const showTrgArr = Array.prototype.slice.call(showTrg, 0)
      const overlay = document.querySelector('.js-modal_overlay')
      const closeTrg = document.querySelectorAll('.js-modal_close')
      const closeTrgArr = Array.prototype.slice.call(closeTrg, 0)
      const html = document.querySelector('html')
      const body = document.querySelector('body')

      const FOCUSABLE_ELEMENTS = [
        'a[href]',
        'area[href]',
        'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
        'select:not([disabled]):not([aria-hidden])',
        'textarea:not([disabled]):not([aria-hidden])',
        'button:not([disabled]):not([aria-hidden])',
        'iframe',
        'object',
        'embed',
        '[contenteditable]',
        '[tabindex]:not([tabindex^="-"])',
      ]

      if (overlay) {
        //モーダル表示
        let beforeFocusedElement = null
        const showModal = (id) => {
          const targetModal = document.querySelector('#' + id)
          const focusableElements = [...targetModal.querySelectorAll(FOCUSABLE_ELEMENTS.join(','))]
          html.classList.add('is-fixed')
          body.classList.add('is-fixed')
          gsap.to(overlay, 0.4, { autoAlpha: 1 })
          gsap.to(targetModal, 0.5, {
            delay: 0.2,
            autoAlpha: 1,
            onComplete: () => {
              focusableElements[0].focus()
            },
          })
        }
        //モーダル非表示
        const closeModal = (id) => {
          const targetModal = document.querySelector('#' + id)
          html.classList.remove('is-fixed')
          body.classList.remove('is-fixed')
          gsap.to(overlay, 0.4, { delay: 0.2, autoAlpha: 0 })
          gsap.to(targetModal, 0.5, { autoAlpha: 0 })
        }

        showTrgArr.forEach(function (target) {
          // クリックイベント
          target.addEventListener(
            'click',
            function (event) {
              const target = event.currentTarget
              let modalId
              if (target.getAttribute('data-src')) {
                modalId = 'img_modal'
                let imgSrc = target.getAttribute('data-src')
                let img = document.getElementById('js-img_modal_src').querySelector('img')
                img.setAttribute('src', imgSrc)
              } else {
                modalId = target.getAttribute('data-modal')
              }
              overlay.setAttribute('data-id', modalId)
              showModal(modalId)
            },
            false
          )
          // Enterを押した時
          target.addEventListener(
            'keypress',
            function (event) {
              if (event.keyCode === 13) {
                const target = event.currentTarget
                let modalId
                if (target.getAttribute('data-src')) {
                  modalId = 'img_modal'
                  let imgSrc = target.getAttribute('data-src')
                  let img = document.getElementById('js-img_modal_src').querySelector('img')
                  img.setAttribute('src', imgSrc)
                } else {
                  modalId = target.getAttribute('data-modal')
                }
                overlay.setAttribute('data-id', modalId)
                showModal(modalId, target)
                beforeFocusedElement = document.activeElement
              }
            },
            false
          )
        })
        closeTrgArr.forEach(function (target) {
          // クリックイベント
          target.addEventListener(
            'click',
            function (event) {
              const target = event.currentTarget
              let modalId = target.getAttribute('data-close')
              closeModal(modalId)
            },
            false
          )
          // Enterを押した時
          target.addEventListener(
            'keypress',
            function (event) {
              if (event.keyCode === 13) {
                const target = event.currentTarget
                const modalId = target.getAttribute('data-close')
                closeModal(modalId)
                beforeFocusedElement.focus()
              }
            },
            false
          )
        })

        // クリックイベント
        overlay.addEventListener(
          'click',
          function (event) {
            const target = event.target
            const modalId = target.getAttribute('data-id')
            closeModal(modalId)
          },
          false
        )
      }
    })
  }
}
