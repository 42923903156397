import axios from 'axios'
import { gsap } from 'gsap'

export class IndexEventModal {
  constructor() {
    document.addEventListener('DOMContentLoaded', function () {
      if (document.getElementById('index-event-calendar') == null) return

      const weekday = ['日', '月', '火', '水', '木', '金', '土']
      const overlay = document.querySelector('.js-modal_overlay')
      const closeTrgs = Array.prototype.slice.call(document.querySelectorAll('.js-modal_close'), 0)
      const ShowTrgs = Array.prototype.slice.call(document.querySelectorAll('.js-calender_modal_trg'), 0)
      let beforeFocusedElement = null
      const FOCUSABLE_ELEMENTS = [
        'a[href]',
        'area[href]',
        'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
        'select:not([disabled]):not([aria-hidden])',
        'textarea:not([disabled]):not([aria-hidden])',
        'button:not([disabled]):not([aria-hidden])',
        'iframe',
        'object',
        'embed',
        '[contenteditable]',
        '[tabindex]:not([tabindex^="-"])',
      ]

      const showModal = () => {
        const targetModal = document.getElementById('js-calender_modal')
        const focusableElements = [...targetModal.querySelectorAll(FOCUSABLE_ELEMENTS.join(','))]
        document.querySelector('html').classList.add('is-fixed')
        document.querySelector('body').classList.add('is-fixed')
        gsap.to(overlay, 0.4, { autoAlpha: 1 })
        gsap.to(targetModal, 0.5, {
          delay: 0.2,
          autoAlpha: 1,
          onComplete: () => {
            beforeFocusedElement = document.activeElement
            focusableElements[0].focus()
          },
        })
      }

      //モーダル非表示
      const closeModal = () => {
        const targetModal = document.getElementById('js-calender_modal')
        document.querySelector('html').classList.remove('is-fixed')
        document.querySelector('body').classList.remove('is-fixed')
        gsap.to(targetModal, 0.5, { autoAlpha: 0 })
        gsap.to(overlay, 0.4, { delay: 0.2, autoAlpha: 0 })
        beforeFocusedElement.focus()
      }

      ShowTrgs.forEach(function (target) {
        // クリックイベント
        target.addEventListener(
          'click',
          function (event) {
            let event_date = target.getAttribute('data-date')
            let format_date = event_date.replace(/\./g, '')
            document.getElementById('js-calender_modal_title').textContent = event_date
            axios.get(`http://fenice-sacay-jp.check-xserver.jp/wp-json/wp/v2/custom/event?id=${format_date}`).then(function (res) {
              let datas = res.data
              const wrap = document.getElementById('js-calender_modal_list')
              wrap.innerHTML = ''
              let elm = ''
              for (let i = 0; i < datas.length; i++) {
                const post = datas[i]
                elm += `<li class="c-performance_item">
                  <a href="${post.link}" aria-label="本日の公演の詳細を見る" tabindex="0">
                    <div class="c-performance_item__title">${post.title}<i><img src="/img/common/ico/angle-right-regular.svg" class="js-svg c-ico" alt="矢印"></i></div>
                    <div class="c-performance_item__info">
                      <p class="item"><i><img src="/img/common/ico/clock-three-light.svg" class="js-svg c-ico" alt="時計"></i>12:00~ / 16:00~</p>
                      <p class="item"><i><img src="/img/common/ico/location-dot-light.svg" class="js-svg c-ico" alt="ピン"></i>大ホール</p>
                    </div>
                  </a>
                </li>`
              }
              wrap.insertAdjacentHTML('afterbegin', elm)
              deSVG('.js-svg', true)
              showModal()
            })
          },
          false
        )
        // クリックイベント
        target.addEventListener(
          'keypress',
          function (event) {
            if (event.keyCode === 13) {
              let event_date = target.getAttribute('data-date')
              let format_date = event_date.replace(/\./g, '')
              document.getElementById('js-calender_modal_title').textContent = event_date
              axios.get(`http://fenice-sacay-jp.check-xserver.jp/wp-json/wp/v2/custom/event?id=${format_date}`).then(function (res) {
                let datas = res.data
                const wrap = document.getElementById('js-calender_modal_list')
                wrap.innerHTML = ''
                let elm = ''
                for (let i = 0; i < datas.length; i++) {
                  const post = datas[i]
                  elm += `<li class="c-performance_item">
                  <a href="${post.link}" aria-label="本日の公演の詳細を見る" tabindex="0">
                    <div class="c-performance_item__title">${post.title}<i><img src="/img/common/ico/angle-right-regular.svg" class="js-svg c-ico" alt="矢印"></i></div>
                    <div class="c-performance_item__info">
                      <p class="item"><i><img src="/img/common/ico/clock-three-light.svg" class="js-svg c-ico" alt="時計"></i>12:00~ / 16:00~</p>
                      <p class="item"><i><img src="/img/common/ico/location-dot-light.svg" class="js-svg c-ico" alt="ピン"></i>大ホール</p>
                    </div>
                  </a>
                </li>`
                }
                wrap.insertAdjacentHTML('afterbegin', elm)
                deSVG('.js-svg', true)
                showModal()
              })
            }
          },
          false
        )
      })

      closeTrgs.forEach(function (target) {
        // クリックイベント
        target.addEventListener(
          'click',
          function (event) {
            closeModal()
          },
          false
        )
        // Enterを押した時
        target.addEventListener(
          'keypress',
          function (event) {
            if (event.keyCode === 13) {
              closeModal()
            }
          },
          false
        )
      })

      // クリックイベント
      overlay.addEventListener(
        'click',
        function (event) {
          closeModal()
        },
        false
      )
    })
  }
}
