import Vue from 'vue/dist/vue.esm.js'

export class Simulation {
  constructor() {
    if (document.getElementById('js-simulation') === null) return

    const dataSection = [
      // isSakai === 0　堺市にお住まいじゃない場合
      {
        大ホール客席全席利用: {
          list: [
            // price === 0
            [
              // holiday === 0
              [65180, 116110, 141570, 181290, 257680, 280090],
              // holiday === 1
              [78420, 139530, 170090, 217950, 309620, 336110],
            ],
            // price === 1
            [
              [98790, 174160, 212870, 272950, 387030, 420640],
              [118140, 208790, 254620, 326930, 463410, 504160],
            ],
            // price === 2
            [
              [131380, 232220, 283140, 363600, 515360, 560180],
              [157870, 279070, 340180, 436940, 619250, 672220],
            ],
            // price === 3
            [
              [196570, 348330, 424720, 544900, 773050, 840270],
              [236290, 418610, 510270, 654900, 928880, 1008330],
            ],
          ],
          option: [
            [0, 0, 0, 0, 0, 0],
            [1510, 2280, 2280, 3790, 4560, 6070],
          ],
        },
        小ホール: {
          list: [
            // price === 0
            [
              // holiday
              [33600, 42760, 42760, 76360, 85520, 119120],
              [39720, 51930, 51930, 91650, 103860, 143580],
            ],
            // price === 1
            [
              [44800, 57020, 57020, 101820, 114040, 158840],
              [52960, 69240, 69240, 122200, 138480, 191440],
            ],
            // price === 2
            [
              [44800, 57020, 57020, 101820, 114040, 158840],
              [52960, 69240, 69240, 122200, 138480, 191440],
            ],
          ],
          option: [
            //楽屋利用なし
            [0, 0, 0, 0],
            //楽屋AorB
            [600, 910, 910, 1510, 1820, 2420],
            //楽屋CorC
            [910, 1060, 1060, 1970, 2120, 3030],
          ],
          ticket: [0, 1],
        },
        大スタジオ本番利用: {
          list: [
            // price === 0
            [
              //holiday
              [25050, 33300, 33300, 58350, 66600, 91650],
              [29940, 40020, 40020, 69960, 80040, 109980],
            ],
            // price === 1
            [
              [33400, 44400, 44400, 77800, 88800, 122200],
              [39920, 53360, 53360, 93280, 106720, 146640],
            ],
            // price === 2
            [
              [33400, 44400, 44400, 77800, 88800, 122200],
              [39920, 53360, 53360, 93280, 106720, 146640],
            ],
          ],
          option: [
            //楽屋利用なし
            [0, 0, 0, 0, 0, 0],
            //楽屋利用
            [750, 910, 910, 1660, 1820, 2570],
          ],
        },
        大スタジオ練習利用: {
          list: [
            // price === 0
            [
              // holiday === 0
              [13440, 17710, 17710, 31150, 35420, 48860],
              // holiday === 1
              [16180, 21690, 21690, 37870, 43380, 59560],
            ],
          ],
          option: [
            [0, 0, 0, 0, 0, 0],
            [750, 910, 910, 1660, 1820, 2570],
          ],
        },
        文化交流室三室一体利用: {
          list: [
            //price == 0
            [
              // holiday === 0
              [19240, 25500, 25500, 44740, 51000, 70240],
              // holiday === 1
              [23220, 31150, 31150, 54370, 62300, 85520],
            ],
            //price == 1
            [
              // holiday === 0
              [25660, 34000, 34000, 59660, 68000, 93660],
              // holiday === 1
              [30960, 41540, 41540, 72500, 83080, 114040],
            ],
            //price == 2
            [
              // holiday === 0
              [25660, 34000, 34000, 59660, 68000, 93660],
              // holiday === 1
              [30960, 41540, 41540, 72500, 83080, 114040],
            ],
          ],
        },
      },
      // isSakai === 1 堺市にお住まいの場合
      {
        大ホール客席全席利用: {
          list: [
            // price === 0 0〜1000円
            [
              // holiday === 0 平日
              [65180, 116110, 141570, 181290, 257680, 280090],
              // holiday === 1 土日祝
              [78420, 139530, 170090, 217950, 309620, 336110],
            ],
            // price === 1　1001〜3000円
            [
              [98790, 174160, 212870, 272950, 387030, 420640],
              [118140, 208790, 254620, 326930, 463410, 504160],
            ],
            // price === 2 3001〜5000円
            [
              [131380, 232220, 283140, 363600, 515360, 560180],
              [157870, 279070, 340180, 436940, 619250, 672220],
            ],
            // price === 3 5001円以上
            [
              [196570, 348330, 424720, 544900, 773050, 840270],
              [236290, 418610, 510270, 654900, 928880, 1008330],
            ],
          ],
          option: [
            [0, 0, 0, 0, 0, 0],
            [1010, 1520, 1520, 2530, 3040, 4050],
          ],
        },
        大ホール客席1428利用: {
          list: [
            [
              [53980, 71290, 71290, 125270, 142580, 196560],
              [64160, 85550, 85550, 149710, 171100, 235260],
            ],
            [
              [81480, 106940, 106940, 188420, 213880, 295360],
              [96750, 128330, 128330, 225080, 256660, 353410],
            ],
            [
              [106940, 142590, 142590, 249530, 285180, 392120],
              [128330, 171110, 171110, 299440, 342220, 470550],
            ],
            [
              [160920, 213880, 213880, 374800, 427760, 588680],
              [192500, 256660, 256660, 449160, 513320, 705820],
            ],
          ],
          price: [0, 1, 2, 3], //0~1000円とか
          option: [
            [0, 0, 0, 0, 0, 0],
            [1010, 1520, 1520, 2530, 3040, 4050],
          ],
        },
        小ホール: {
          list: [
            [
              // 入場料なし
              [22400, 28510, 28510, 50910, 57020, 79420], //平日
              [26480, 34620, 34620, 61100, 69240, 95720], //土日祝
            ],
            [
              // 入場料あり
              [33600, 42760, 42760, 76360, 85520, 119120], //平日
              [39720, 51930, 51930, 91650, 103860, 143580], //土日祝
            ],
            [
              // 販売など営利目的
              [33600, 42760, 42760, 76360, 85520, 119120], //平日
              [39720, 51930, 51930, 91650, 103860, 143580], //土日祝
            ],
          ],
          option: [
            [0, 0, 0, 0, 0, 0],
            [400, 610, 610, 1010, 1220, 1620], //楽屋A・B
            [610, 710, 710, 1320, 1420, 2030], //楽屋C・D
          ],
          ticket: [0, 1],
        },
        大スタジオ本番利用: {
          list: [
            [
              // 入場料なし
              [16700, 22200, 22200, 38900, 44400, 61100], //平日
              [19960, 26680, 26680, 46640, 53360, 73320], //土日祝
            ],
            [
              // 入場料あり
              [25050, 33300, 33300, 58350, 66600, 91650], //平日
              [29940, 40020, 40020, 69960, 80040, 109980], //土日祝
            ],
            [
              // 販売など営利目的
              [2505, 3330, 3330, 5835, 6660, 91650], //平日
              [2994, 4002, 4002, 6996, 8004, 109980], //土日祝
            ],
          ],
          option: [
            [0, 0, 0, 0, 0, 0],
            [500, 610, 610, 1110, 1220, 1720],
          ],
        },
        大スタジオ練習利用: {
          list: [
            [
              // holiday === 0
              [8960, 11810, 11810, 20770, 23620, 32580],
              // holiday === 1
              [10790, 14460, 14460, 25250, 28920, 39710],
            ],
          ],
          option: [
            [0, 0, 0, 0, 0, 0],
            [500, 610, 610, 1110, 1220, 1720],
          ],
        },
        文化交流室三室一体利用: {
          list: [
            [
              // holiday === 0
              [12830, 17000, 17000, 29830, 34000, 46830],
              // holiday === 1
              [15480, 20770, 20770, 36250, 41540, 57020],
            ],
            [
              // holiday === 0
              [19240, 25500, 25500, 44740, 51000, 70240],
              // holiday === 1
              [23220, 31150, 31150, 54370, 62300, 85520],
            ],
            [
              // holiday === 0
              [19240, 25500, 25500, 44740, 51000, 70240],
              // holiday === 1
              [23220, 31150, 31150, 54370, 62300, 85520],
            ],
          ],
        },
      },
    ]

    const app1 = new Vue({
      el: '#js-simulation',
      data: {
        isSakai: '',
        isFacility: '',
        isTicket: 0,
        isHoliday: '',
        isSection: '',
        isOption: 0,
      },
      computed: {
        resultPrice: function () {
          if (this.isSakai && this.isFacility && this.isHoliday && this.isSection) {
            let price = dataSection[this.isSakai][this.isFacility]['list'][this.isTicket][this.isHoliday][this.isSection]
            if (this.isOption) {
              //楽屋利用がある場合
              let option = dataSection[this.isSakai][this.isFacility]['option'][this.isOption][this.isSection]
              price = price + option
            } else {
              //楽屋利用が無い場合
              price = price
            }
            let result = price.toLocaleString()

            return result
          }
        },
      },
    })
  }
}
