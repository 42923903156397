export class SimulationDisabled {
  constructor() {
    if (document.getElementById('js-simulation') === null) return
    const isSakai = document.querySelector('.js-simulation_issakai')
    let isSakaiList = Array.prototype.slice.call(isSakai.querySelectorAll(`input[type='radio']`), 0)

    isSakaiList.forEach(function (el) {
      el.addEventListener('change', (event) => {
        let $this = event.currentTarget
        let val = $this.value
        if (val === '0') {
          //堺市外
          document.getElementById('facility-1').checked = false
          document.getElementById('facility-1').disabled = true
        } else {
          document.getElementById('facility-1').disabled = false
        }
      })
    })
  }
}
