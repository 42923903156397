'use strict'

require('picturefill')
require('intersection-observer')

// import { Budoux } from './lib/Budoux'
import { ShowPagetop } from './lib/ShowPagetop'
import { Smooth } from './lib/Smooth'
import { TelGrant } from './lib/TelGrant'
import { EqualHeight } from './lib/EqualHeight'
import { Drawer } from './lib/Drawer'
import { Svg } from './lib/Svg'
import { ScrollAnimation } from './lib/ScrollAnimation'
import { IeModal } from './lib/IeModal'
import { WindowStatus } from './lib/WindowStatus'
import { ViewportHeight } from './lib/ViewportHeight'
import { Zipcode } from './lib/Zipcode'
import { MegaMenu } from './lib/MegaMenu'
import { PickupSlider } from './lib/PickupSlider'
import { AboutGallery } from './lib/AboutGallery'
import { Ui } from './lib/Ui'
import { KVSlider } from './lib/KVSlider'
import { Modal } from './lib/Modal'
import { TextSplit } from './lib/TextSplit'
import { Opening } from './lib/Opening'
import { Accordion } from './lib/Accordion'
import { WaySlider } from './lib/WaySlider'
import { EnjoySlider } from './lib/EnjoySlider'
import { EventCalendar } from './lib/EventCalendar'
import { Anchor } from './lib/Anchor'
import { FacilitySlider } from './lib/FacilitySlider'
import { IndexEventCalendar } from './lib/IndexEventCalendar'
import { IndexEventModal } from './lib/IndexEventModal'

// new Budoux()
new IeModal('サイト名', 'サイトURL')
new WindowStatus()
new ViewportHeight()
new Svg()
new ScrollAnimation()
new Drawer()
new ShowPagetop()
new Smooth()
new EqualHeight('.js-max_height')
new TelGrant()
new Zipcode()
new MegaMenu()
new PickupSlider()
new AboutGallery()
new Ui()
new KVSlider()
new Modal()
new TextSplit()
new Opening()
new Accordion()
new WaySlider()
new EnjoySlider()
new EventCalendar()
new Anchor()
new FacilitySlider()
new IndexEventCalendar()
new IndexEventModal()
