import Swiper, { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'

export class PickupSlider {
  constructor() {
    if (document.getElementById('js-pickup_slider') === null) return
    const pickup = new Swiper('#js-pickup_slider', {
      modules: [Navigation],
      slidesPerView: 1,
      spaceBetween: 20,
      centeredSlides: true,
      navigation: {
        nextEl: '.js-arrow_next',
        prevEl: '.js-arrow_prev',
      },
      breakpoints: {
        767: {
          slidesPerView: 1.8,
          spaceBetween: 20,
          centeredSlides: false,
        },
        1024: {
          slidesPerView: 2.4,
          spaceBetween: 35,
          centeredSlides: false,
        },
      },
    })
  }
}
