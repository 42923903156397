import { gsap } from 'gsap'

export class SimulationDisplay {
  constructor() {
    if (document.querySelector('.js-facility_trg') === null) return
    const facilityTrg = document.querySelector('.js-facility_trg')
    const targetArr = Array.prototype.slice.call(document.querySelectorAll('.js-facility_target'), 0)
    let checkArr = Array.prototype.slice.call(facilityTrg.querySelectorAll(`input[type='radio']`), 0)

    checkArr.forEach(function (el) {
      el.addEventListener('change', (event) => {
        let $this = event.currentTarget
        let val = $this.value
        targetArr.forEach(function (target) {
          let inputArr = Array.prototype.slice.call(target.querySelectorAll(`input[type='radio']`), 0)
          let data = target.getAttribute('data-facility')
          let result = data.indexOf(val, 0)
          if (result !== -1) {
            gsap.to(target, { autoAlpha: 1, display: 'flex' })
          } else {
            gsap.to(target, { autoAlpha: 0, display: 'none' })
          }
        })
      })
    })

    if (document.querySelector('.js-attached_facility_trg') === null) return
    const attachedFacilityTrg = document.querySelector('.js-attached_facility_trg')
    let attachedArr = Array.prototype.slice.call(attachedFacilityTrg.querySelectorAll(`input[type='radio']`), 0)
    let attachedTargetArr = Array.prototype.slice.call(document.querySelectorAll('.js-attached_item'), 0)
    attachedArr.forEach(function (el) {
      el.addEventListener('change', (event) => {
        let $this = event.currentTarget
        let val = $this.value
        attachedTargetArr.forEach(function (target) {
          let data = target.getAttribute('data-cat')
          if (val === data) {
            gsap.to(target, { autoAlpha: 1, display: 'flex' })
          } else {
            gsap.to(target, { autoAlpha: 0, display: 'none' })
          }
        })
      })
    })
  }
}
