import Swiper, { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'

export class FacilitySlider {
  constructor() {
    if (document.getElementById('js-facility_kv_slider') === null) return
    const slider = document.getElementById('js-facility_kv_slider')
    const slideList = slider.querySelectorAll('.swiper-slide')
    let options = {}

    if (slideList.length > 1) {
      options = {
        modules: [Navigation],
        loop: true,
        centeredSlides: true,
        slidesPerView: 1.2,
        spaceBetween: 15,
        breakpoints: {
          767: {
            slidesPerView: 1.63,
            spaceBetween: 40,
          },
        },
        navigation: {
          nextEl: '.js-arrow_next',
          prevEl: '.js-arrow_prev',
        },
      }
    } else {
      options = {
        modules: [Navigation],
        loop: false,
        centeredSlides: true,
        slidesPerView: 1.2,
        spaceBetween: 15,
        breakpoints: {
          767: {
            slidesPerView: 1.63,
            spaceBetween: 40,
          },
        },
        navigation: {
          nextEl: '.js-arrow_next',
          prevEl: '.js-arrow_prev',
        },
      }
    }
    new Swiper('#js-facility_kv_slider', options)
  }
}
