import Vue from 'vue/dist/vue.esm.js'

export class SimulationTime {
  constructor() {
    if (document.getElementById('js-simulation_time') === null) return

    const dataSection = [
      // isSakai === 0　堺市にお住まいじゃない場合
      {
        文化交流室: [
          [1360, 1820], //平日
          [1680, 2240], //土日祝
        ],
        文化交流室AB: [
          [2720, 3640], //平日
          [3360, 4480], //土日祝
        ],
        文化交流室BC: [
          [2720, 3640], //平日
          [3360, 4480], //土日祝
        ],
        文化交流室ABC: [
          [4080, 5460], //平日
          [5040, 6720], //土日祝
        ],
        多目的室: [[3040, 4060]],
        小スタジオA: [[750, 1000]],
        小スタジオB: [[1210, 1620]],
        小スタジオC: [[1510, 2020]],
      },
      // isSakai === 1 堺市にお住まいの場合
      {
        文化交流室: [
          [910, 1360],
          [1120, 1680],
        ],
        文化交流室AB: [
          [1820, 2720], //平日
          [2240, 3360], //土日祝
        ],
        文化交流室BC: [
          [1820, 2720], //平日
          [2240, 3360], //土日祝
        ],
        文化交流室ABC: [
          [2730, 4080], //平日
          [3360, 5040], //土日祝
        ],
        多目的室: [[2030, 3040]],
        小スタジオA: [[500, 750]],
        小スタジオB: [[810, 1210]],
        小スタジオC: [[1010, 1510]],
      },
    ]

    const app2 = new Vue({
      el: '#js-simulation_time',
      data: {
        isSakai: '',
        isFacility: '',
        isTicket: '',
        isHoliday: '0',
        isTime: '',
      },
      computed: {
        resultPrice: function () {
          if (this.isSakai && this.isFacility && this.isTicket && this.isHoliday) {
            let price = dataSection[this.isSakai][this.isFacility][this.isHoliday][this.isTicket]
            if (this.isTime) {
              price = price * this.isTime
            }
            let result = price.toLocaleString()

            return result
          }
        },
      },
    })
  }
}
