export class EventSearchClear {
  constructor() {
    if (document.getElementById('js-event_search_clear') === null) return

    let checkList = Array.prototype.slice.call(document.querySelectorAll('input[type="checkbox"]'), 0)
    let keywordInput = document.getElementById('js-event_search_keyword')
    const btn = document.getElementById('js-event_search_clear')
    btn.addEventListener('click', (event) => {
      keywordInput.value = ''
      checkList.forEach((target) => {
        target.checked = false
      })
    })
  }
}
